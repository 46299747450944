import React, { useState } from 'react';
import './PartnerCard.css'; // Import CSS for styling
import Popup from './Popup'; // Import the Popup component

function PartnerCard({ image, title, description }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setIsFadingOut(false);
  };

 const handleClosePopup = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsPopupOpen(false);
      setIsFadingOut(false);
    }, 300); // Match this duration with the CSS transition duration
  };

  return (
    <div className="partner-card">
      <img src={image} alt={title} className="partner-card__image" />
      <h3 className="partner-card__title">{title}</h3>
      <p className="partner-card__description">{description}</p>
      <button className="partner-card__button" onClick={handleOpenPopup}>More Info</button>

      {isPopupOpen && (
        <Popup 
          title={title} 
          description={description} 
          image={image} 
          onClose={handleClosePopup} 
          className={isFadingOut ? 'fade-out' : ''}
        />
      )}
    </div>
  );
}

export default PartnerCard;