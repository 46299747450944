import React from 'react';
import PartnerCard from '../sources/PartnerCard';
import partnerImage1 from '../image_lib/partner/sample_script.png';
import partnerImage2 from '../image_lib/partner/sample_script2.png';
import partnerImage3 from '../image_lib/partner/sample_script3.png';
import '../App.css';

function Partner() {
  return (
    <div className="full-page">
      <header className="App-header">
        <h1>Partners</h1>
        <div className="partner-cards-container">
          <PartnerCard 
            image={partnerImage1} 
            title="Growpai" 
            description="Another amazing internal, by HertzXD" 
          />
          {/* <PartnerCard 
            image={partnerImage2} 
            title="Partner 2" 
            description="Description for Partner 2" 
          />
          <PartnerCard 
            image={partnerImage3} 
            title="Partner 3" 
            description="Description for Partner 3" 
          /> */}
        </div>
      </header>
    </div>
  );
}

export default Partner;

